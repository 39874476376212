<template>
  <div class="lad-home-cover">
    <div class="home-cover-top">
      <el-container>
        <el-main>
          <h1 class="hero-title">{{ $t(`${base}.cover.title`) }}</h1>
          <p class="hero-description">{{ $t(`${base}.cover.description`) }}</p>
        </el-main>
      </el-container>
    </div>

    <div
      class="image-with-shadow background"
      :style="{ 'background-image': `url(${cover.srcset})` }"
    >
      <LadImage
        :src="cover.src"
        :include-all-sources="true"
        :preloading="true"
        :lazy-loading="false"
        class="image--cover"
      />
    </div>
  </div>
</template>

<script>
import CacheComponentMixin from '@/utils/mixin/cache-components'

export default {
  name: 'LadHomeSlider',
  components: {
    LadImage: () =>
      import(
        /* webpackChunkName: "lad-image" */ '@/components/frontends/Image/index'
      ),
  },
  mixins: [CacheComponentMixin],
  data() {
    return {
      base: 'home',
    }
  },
  computed: {
    cover() {
      return {
        src: `${process.env.DO_SPACES_CDN_ENDPOINT}/web-client/covers/home-02.jpg`,
        srcset: require(`@/db/home_slider`).default.base64svg,
      }
    },
  },
}
</script>

<style lang="scss">
.lad-home-cover {
  position: relative;
  height: 65vh;
  min-height: 380px;

  .image-with-shadow {
    width: 100%;
    height: 100%;
    z-index: 5;

    .image--cover {
      source,
      img {
        height: 100% !important;
      }
    }
  }

  .background {
    background-size: cover; /* Ajusta el tamaño de la imagen */
    background-position: center; /* Centra la imagen */
    background-repeat: no-repeat; /* Evita que la imagen se repita */
  }

  .home-cover-top {
    position: absolute;
    width: 100%;
    color: #fff;
    z-index: 3;
    bottom: 15%;

    .el-main {
      max-width: 70%;
    }

    .hero-title {
      font-size: 4em;
      line-height: 1.2;
      text-shadow: 2px 2px 15px #464646;
      margin-bottom: 0.3em;
    }

    .hero-description {
      font-size: 1.5em;
      font-weight: 400;
      text-shadow: 2px 2px 15px #464646;
      font-style: italic;
      color: #fff;
      margin: 1em 0;
    }

    @media screen and (max-width: 1180px) {
      .el-main {
        padding: 0 20px;
      }
    }

    @media screen and (max-width: 990px) {
      .el-main {
        max-width: 85%;
      }
    }

    @media screen and (max-width: 700px) {
      .hero-title {
        font-size: 3.5em;
        margin-bottom: 0.3em;
      }
    }

    @media screen and (max-width: 600px) {
      .el-main {
        max-width: inherit;
      }

      .hero-title {
        font-size: 1.8em;
        line-height: 1.36363636em;
        margin-top: 1em;
      }

      .hero-description {
        font-size: 1.3em;
        line-height: 1.68421053em;
        margin-top: 0.3em;
      }
    }
  }
}
</style>
